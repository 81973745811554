import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
	container: {
		width: '100%',
		WebkitColumnGap: '1rem',
		MozColumnGap: '1rem',
		columnGap: '1rem',
		WebkitColumnFill: 'balance',
		MozColumnFill: 'balance',
		columnFill: 'balance',
		WebkitColumnWidth: '20rem',
		MozColumnWidth: '20rem',
		columnWidth: '20rem',
	},
	card: {
		margin: '0.5rem 0',
		display: 'inline-block',
		width: '100%',
	},
});

const PasswordCard = (props) => {
	const classes = useStyles(props);
	return (
		<Card className={classes.card}>
			<CardHeader title={<Translate id="settings.password.title" />} subheader={<Translate id="settings.password.subtitle" />} />
			<CardContent>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<TextField
							label={props.translate('settings.password.old')}
							autoComplete="new-password"
							name="old"
							value={props.old}
							onChange={props.onChange}
							type="password"
							fullWidth
						/>
					</Grid>
					<Grid item>
						<TextField
							label={props.translate('settings.password.new')}
							autoComplete="new-password"
							name="new"
							value={props.new}
							onChange={props.onChange}
							type="password"
							fullWidth
						/>
					</Grid>
					<Grid item>
						<TextField
							label={props.translate('settings.password.new2')}
							autoComplete="new-password"
							name="new2"
							value={props.new2}
							onChange={props.onChange}
							type="password"
							fullWidth
						/>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions>
				<Grid container direction="row" justify="flex-end" alignItems="center">
					<Button onClick={props.submit}>
						<Translate id="settings.confirm" />
					</Button>
				</Grid>
			</CardActions>
		</Card>
	);
};

const LocaleCard = (props) => {
	const classes = useStyles(props);
	return (
		<Card className={classes.card}>
			<CardHeader title={<Translate id="settings.locale.title" />} subheader={<Translate id="settings.locale.subtitle" />} />
			<CardContent>
				<RadioGroup aria-label="locale" name="locale" value={props.locale} onChange={props.onChange}>
					<FormControlLabel value="it" control={<Radio color="primary" />} label="Italiano" />
					{/* <FormControlLabel value="en" control={<Radio color="primary" />} label="English" /> */}
				</RadioGroup>
			</CardContent>
		</Card>
	);
};

const NameCard = (props) => {
	const classes = useStyles(props);
	return (
		<Card className={classes.card}>
			<CardHeader title={<Translate id="settings.name.title" />} subheader={<Translate id="settings.name.subtitle" />} />
			<CardContent>
				<TextField label={props.translate('settings.name.label')} name="name" value={props.name} onChange={props.onChange} type="text" fullWidth />
			</CardContent>
			<CardActions>
				<Grid container direction="row" justify="flex-end" alignItems="center">
					<Button onClick={props.submit}>
						<Translate id="settings.confirm" />
					</Button>
				</Grid>
			</CardActions>
		</Card>
	);
};

const Settings = (props) => {
	const classes = useStyles(props);
	return (
		<div className={classes.container}>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={props.snackbar !== null}
				autoHideDuration={6000}
				onClose={props.onSnackbarClose}
			>
				<Alert
					elevation={6}
					variant="filled"
					severity="info"
					color="info"
					onClose={props.onSnackbarClose}
					icon={false}
					action={
						<IconButton size="small" aria-label="close" color="inherit" onClick={props.onSnackbarClose}>
							<CloseIcon fontSize="small" />
						</IconButton>
					}
				>
					{props.snackbar === null ? '' : props.translate(`settings.snackbar.${props.snackbar}`)}
				</Alert>
			</Snackbar>
			<PasswordCard {...props.passw} onChange={props.onChange} translate={props.translate} submit={props.submitPassword} />
			<LocaleCard locale={props.locale} onChange={props.onChange} translate={props.translate} />
			<NameCard name={props.name} onChange={props.onChange} translate={props.translate} submit={props.submitName} />
		</div>
	);
};

export default withLocalize(Settings);
