import React from 'react';
import { Translate } from 'react-localize-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getCurrentUser as CognitoGetCurrentUser } from '../../aws/cognito';

const timeToString = (time, locale) => {
	let date = new Date(time * 1000);
	return date.toLocaleTimeString(locale, { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
};

const hoursToString = (time) => {
	let hours = Math.floor(time / 3600)
		.toString()
		.padStart(2, '0');
	let minutes = Math.floor((time % 3600) / 60)
		.toString()
		.padStart(2, '0');
	let seconds = Math.floor(time % 60)
		.toString()
		.padStart(2, '0');
	return `${hours}h${minutes}m${seconds}s`;
};

class HomeDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: true,
			date: new Date(),
			status: [],
			rowsPerPage: 10,
			page: 0,
		};
		this.fetchDetails = this.fetchDetails.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	handleChangePage(e, page) {
		this.secureSetState({ page });
	}

	handleChangeRowsPerPage(e) {
		this.secureSetState({ page: 0, rowsPerPage: Math.abs(e.target.value) });
	}

	handleChangeDate(date) {
		this.secureSetState({ date }, this.fetchDetails);
	}

	fetchDetails() {
		this.secureSetState({ processing: true }, async () => {
			try {
				await CognitoGetCurrentUser();
				let from = new Date(this.state.date.getTime());
				let to = new Date(this.state.date.getTime());
				from.setHours(0, 0, 0, 0);
				to.setHours(23, 59, 59, 999);
				let fromTime = Math.round(from.getTime() / 1000);
				let toTime = Math.round(to.getTime() / 1000);
				let r = await fetch(`https://api.bernardimixers.com/status/${this.props.mixer.id}?from=${fromTime}&to=${toTime}`, {
					method: 'GET',
					headers: {
						'X-Api-Key': '3dFmMoyN725JzNimeIuBw47wEHJzMpsd1EHAx8K1',
					},
				});
				let resp = await r.json();
				if (resp.hasOwnProperty('status')) {
					this.secureSetState({
						status: resp.status,
						processing: false,
					});
				}
			} catch (err) {
				console.log(err);
				if (err === 'not_authenticated') {
					window.location.href = '/';
					return;
				}

				this.secureSetState({
					mixers: [],
					count: null,
					processing: false,
				});
			}
		});
	}

	componentDidMount() {
		this.isMounted = true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.open && !this.props.open) {
			this.secureSetState({
				processing: false,
				status: [],
				date: new Date(),
				rowsPerPage: 10,
				page: 0,
			});
		} else if (!prevProps.open && this.props.open) {
			this.fetchDetails();
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Dialog open={this.props.open} onClose={this.props.onClose} maxWidth="lg">
				<LinearProgress hidden={!this.state.processing} />
				<DialogContent>
					<Grid container direction="row" alignItems="flex-end" justify="space-between" spacing={2}>
						<Grid item xs={10} sm={11} container direction="row" alignItems="flex-end" justify="flex-start">
							<Grid item xs={8} sm={4}>
								<KeyboardDatePicker
									disableToolbar
									variant="inline"
									format="dd/MM/yyyy"
									margin="normal"
									label={<Translate id="home.details.date" />}
									value={this.state.date}
									onChange={(d) => this.handleChangeDate(d)}
									KeyboardButtonProps={{ 'aria-label': 'change date' }}
									allowKeyboardControl
									animateYearScrolling
									autoOk
									disableFuture
								/>
							</Grid>
							<Grid item xs={2} sm={1}>
								<IconButton aria-label="refresh" onClick={this.fetchDetails} disabled={this.state.date === null}>
									<RefreshIcon />
								</IconButton>
							</Grid>
						</Grid>
						<Grid item xs={2} sm={1}>
							<IconButton aria-label="close" onClick={this.props.onClose}>
								<CloseIcon />
							</IconButton>
						</Grid>
					</Grid>
					<TableContainer>
						<Table size="small" aria-label="Status table">
							<TableHead>
								<TableRow>
									<TableCell>
										<Translate id="home.details.date" />
									</TableCell>
									<TableCell>
										<Translate id="home.details.active" />
									</TableCell>
									<TableCell>
										<Translate id="home.details.working" />
									</TableCell>
									<TableCell align="right">
										<Translate id="home.details.temperature" />
									</TableCell>
									<TableCell align="right">
										<Translate id="home.details.errors.inverter" />
									</TableCell>
									<TableCell align="right">
										<Translate id="home.details.errors.temperature" />
									</TableCell>
									<TableCell align="right">
										<Translate id="home.details.errors.sto" />
									</TableCell>
									<TableCell>
										<Translate id="home.details.device.title" />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.status
									.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
									.map((row) => (
										<TableRow key={row.date}>
											<TableCell>{timeToString(row.date)}</TableCell>
											<TableCell>{hoursToString(row.active)}</TableCell>
											<TableCell>{hoursToString(row.working)}</TableCell>
											<TableCell align="right">{row.temperature}</TableCell>
											<TableCell align="right">{row.errors.inverter}</TableCell>
											<TableCell align="right">{row.errors.temperature}</TableCell>
											<TableCell align="right">{row.errors.sto}</TableCell>
											<TableCell>
												<Typography component="p" variant="caption">
													<Translate id={`home.details.device.os.${row.device.os}`} />
													{` v${row.device.version}`}
													<br />
													{`(${row.device.model})`}
													<br />
													<Translate id="home.details.device.app" />
													{` ${row.device.app}`}
												</Typography>
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 100]}
						component="div"
						count={this.state.status.length}
						rowsPerPage={this.state.rowsPerPage}
						page={this.state.page}
						onChangePage={this.handleChangePage}
						onChangeRowsPerPage={this.handleChangeRowsPerPage}
						labelRowsPerPage={<Translate id="rowsPerPage" />}
					/>
				</DialogContent>
			</Dialog>
		);
	}
}

export default HomeDetails;
