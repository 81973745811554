import React from 'react';
import { getCurrentUser as CognitoGetCurrentUser } from '../../aws/cognito';
import UserContext from '../../context';
import Home from './presentation';

class HomeContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			mixers: [],
			rowsPerPage: 10,
			page: 0,
			selectedMixer: null,
			filters: {
				open: false,
				value: '',
			},
		};
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.filterChanged = this.filterChanged.bind(this);
		this.filterSearch = this.filterSearch.bind(this);
		this.openDetails = this.openDetails.bind(this);
		this.closeDetails = this.closeDetails.bind(this);
		this.fetchMixers = this.fetchMixers.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
		this._mixers = [];
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	handleChangePage(e, page) {
		this.secureSetState({ page });
	}

	handleChangeRowsPerPage(e) {
		this.secureSetState({ page: 0, rowsPerPage: Math.abs(e.target.value) });
	}

	toggleFilters() {
		this.secureSetState({ filters: { open: !this.state.filters.open, value: '' }, mixers: this._mixers.slice() });
	}

	filterChanged(e) {
		let value = e.target.value;
		this.secureSetState({ filters: { open: true, value: value } }, this.filterSearch);
	}

	filterSearch() {
		if (this.state.processing) {
			return;
		}
		let value = this.state.filters.value.trim().toLowerCase();
		if (value.length === 0) {
			this.secureSetState({ mixers: this._mixers.slice() });
			return;
		}
		let mixers = this._mixers.filter((el) => {
			if (el.id.toLowerCase().includes(value) || el.name.toLowerCase().includes(value) || el.bluetooth.toLowerCase().includes(value)) {
				return true;
			}
			return false;
		});
		this.secureSetState({ mixers });
	}

	fetchMixers() {
		this.secureSetState({ processing: true, filtrers: { open: false, value: '' } }, async () => {
			this._mixers = [];
			try {
				await CognitoGetCurrentUser();
				let r = await fetch('https://api.bernardimixers.com/mixers', {
					method: 'GET',
					headers: {
						'X-Api-Key': '3dFmMoyN725JzNimeIuBw47wEHJzMpsd1EHAx8K1',
					},
				});
				let resp = await r.json();
				if (resp.hasOwnProperty('mixers')) {
					this._mixers = resp.mixers.sort((a, b) => b.lastUpdate - a.lastUpdate);
					this.secureSetState({
						mixers: this._mixers.slice(),
						processing: false,
					});
				}
			} catch (err) {
				console.log(err);
				if (err === 'not_authenticated') {
					window.location.href = '/';
					return;
				}

				this.secureSetState({
					mixers: [],
					processing: false,
				});
			}
		});
	}

	openDetails(mixer) {
		return () => this.secureSetState({ selectedMixer: mixer });
	}

	closeDetails() {
		this.secureSetState({ selectedMixer: null });
	}

	async componentDidMount() {
		this.isMounted = true;
		document.title = 'Home | Bernardi mixers';
		this.fetchMixers();
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Home
				{...this.state}
				locale={this.context.locale}
				handleChangePage={this.handleChangePage}
				handleChangeRowsPerPage={this.handleChangeRowsPerPage}
				fetchMixers={this.fetchMixers}
				openDetails={this.openDetails}
				closeDetails={this.closeDetails}
				toggleFilters={this.toggleFilters}
				filterChanged={this.filterChanged}
				filterSearch={this.filterSearch}
			/>
		);
	}
}

HomeContainer.contextType = UserContext;
export default HomeContainer;
