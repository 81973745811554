import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import { withRouter, Route, Switch, Link } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import {
	refresh as CognitoRefresh,
	getAttributes as CognitoGetAttributes,
	updateAttribute as CognitoUpdateAttribute,
	logout as CognitoLogout,
} from '../../aws/cognito';
import UserContext from '../../context';
import translations from '../../l10n';
import Login from '../login';
import Home from '../home';
import Users from '../users';
import Settings from '../settings';

import itLocale from 'date-fns/locale/it';
import { AscyncSleep } from '../../utilities';
import LogoImg from '../../img/logo.png';

const styles = (theme) => ({
	root: {
		backgroundColor: '#eeeeee',
		width: '100%',
		minHeight: '100vh',
	},
	main: {
		backgroundColor: 'transparent',
		width: '60rem',
		maxWidth: '95vw',
		marginTop: '2rem',
	},
	header: {
		marginBottom: '1rem',
	},
	img: {
		height: '4rem',
	},
	userContainer: {
		marginTop: '2rem',
		padding: '0.5rem 1rem',
		backgroundColor: '#eeeeee',
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	container: {
		width: '100%',
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			paddingRight: '0',
		},
	},
	sidenav: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		// position: 'absolute',
		// top: 0,
		// left: 0,
		// bottom: 0,
		// width: '5rem',
		marginRight: '1rem',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			marginRight: 0,
			flexDirection: 'row',
			justifyContent: 'space-around',
			marginTop: '1rem',
			marginBottom: '1rem',
		},
	},
	logoutButton: {
		marginTop: '2rem',
		[theme.breakpoints.down('xs')]: {
			marginTop: 'unset',
		},
	},
	content: {
		overflow: 'auto',
		// marginLeft: '5rem',
		// width: '100%',
		padding: 4,
		flex: 1,
		[theme.breakpoints.down('xs')]: {
			margin: '0',
		},
	},
	loaderContainer: {
		height: '10rem',
	},
	hasMargin: {
		marginLeft: '5rem',
	},
});

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ready: false,
			context: {
				id: '',
				name: '',
				locale: '',
				changeLocale: this.changeLocale,
				changeName: this.changeName,
			},
		};
		this.loopRefresh = this.loopRefresh.bind(this);
		this.changeName = this.changeName.bind(this);
		this.changeLocale = this.changeLocale.bind(this);
		this.logout = this.logout.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;

		let defaultLanguage = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'it';
		defaultLanguage = defaultLanguage.split('-')[0].toLowerCase();
		if (['it' /*, 'en'*/].indexOf(defaultLanguage) === -1) {
			defaultLanguage = 'it';
		}
		this.defaultLanguage = defaultLanguage;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	async logout() {
		await CognitoLogout();
		setTimeout(() => (window.location.href = '/'), 100);
	}

	async loopRefresh() {
		for (;;) {
			try {
				await AscyncSleep(600000);
				await CognitoRefresh(false);
			} catch (err) {
				console.log(err);
			}
		}
	}

	async changeName(n) {
		let { context } = this.state;
		context.name = n;
		this.secureSetState({ context });
		try {
			await CognitoUpdateAttribute('name', n);
		} catch (err) {
			console.log(err);
		}
	}

	async changeLocale(l) {
		this.props.setActiveLanguage(l);
		let { context } = this.state;
		context.locale = l;
		this.secureSetState({ context });
		try {
			await CognitoUpdateAttribute('locale', l);
		} catch (err) {
			console.log(err);
		}
	}

	async componentDidMount() {
		this.isMounted = true;
		this.props.initialize({
			languages: [
				// { name: 'English', code: 'en' },
				{ name: 'Italian', code: 'it' },
			],
			options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.defaultLanguage },
		});
		// this.props.addTranslationForLanguage(translations.en, 'en');
		this.props.addTranslationForLanguage(translations.it, 'it');
		try {
			await CognitoRefresh(true);
			let attributes = await CognitoGetAttributes();
			let context = {
				id: attributes.id || '',
				name: attributes.name || '',
				locale: attributes.locale || this.defaultLanguage,
				changeLocale: this.changeLocale,
				changeName: this.changeName,
			};
			this.props.setActiveLanguage(context.locale);
			this.secureSetState({ ready: true, context: context });
			await this.loopRefresh();
		} catch (err) {
			console.log(err);
			if (this.props.history.location.pathname !== '/login' && !/^\/forgot(\/[a-zA-Z0-9]+)?$/gim.test(this.props.history.location.pathname)) {
				this.props.history.replace('/login');
			}
			setTimeout(() => this.secureSetState({ ready: true }), 1000);
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		let locale = this.defaultLanguage;
		if (this.state.ready) {
			let l = this.state.context.locale.length === 0 ? this.defaultLanguage : this.state.context.locale;
			switch (l) {
				case 'it':
					locale = itLocale;
					break;

				default:
					locale = itLocale;
					break;
			}
		}

		return (
			<UserContext.Provider value={this.state.context}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
					<Grid container direction="column" alignItems="center" justify="flex-start" className={this.props.classes.root}>
						<Grid container direction="column" alignItems="flex-start" justify="flex-start" className={this.props.classes.main}>
							<Grid container direction="row" alignItems="center" justify="space-between" className={this.props.classes.header}>
								<img src={LogoImg} alt="logo" className={this.props.classes.img} />
								{this.state.context.id.length > 0 && (
									<Paper className={this.props.classes.userContainer}>
										<Typography color="textPrimary" variant="caption">
											{this.state.context.name}
										</Typography>
										<br />
										<Typography color="textSecondary" variant="caption">
											{this.state.context.id}
										</Typography>
									</Paper>
								)}
							</Grid>
							<Grid container direction="row" className={this.props.classes.container}>
								{this.state.ready ? (
									<>
										{this.props.location.pathname !== '/login' && (
											<div className={this.props.classes.sidenav}>
												<Tooltip title={this.props.translate('sidenav.home')} aria-label="home" placement="right" arrow>
													<Link to="/">
														<IconButton aria-label="home">
															<HomeIcon />
														</IconButton>
													</Link>
												</Tooltip>
												<Tooltip title={this.props.translate('sidenav.users')} aria-label="users" placement="right" arrow>
													<Link to="/users">
														<IconButton aria-label="users">
															<PeopleIcon />
														</IconButton>
													</Link>
												</Tooltip>
												<Tooltip title={this.props.translate('sidenav.settings')} aria-label="settings" placement="right" arrow>
													<Link to="/settings">
														<IconButton aria-label="settings">
															<SettingsIcon />
														</IconButton>
													</Link>
												</Tooltip>
												<Tooltip title={this.props.translate('sidenav.logout')} aria-label="logout" placement="right" arrow>
													<IconButton aria-label="logout" className={this.props.classes.logoutButton} onClick={this.logout}>
														<ExitToAppIcon />
													</IconButton>
												</Tooltip>
											</div>
										)}
										<div className={this.props.classes.content}>
											<Switch>
												<Route path="/login" exact component={Login} />
												<Route path="/settings" exact component={Settings} />
												<Route path="/users" exact component={Users} />
												<Route path="/" exact component={Home} />
											</Switch>
										</div>
									</>
								) : (
									<Card className={this.props.classes.content}>
										<CardContent>
											<Grid
												container
												direction="column"
												alignItems="center"
												justify="center"
												className={this.props.classes.loaderContainer}
											>
												<CircularProgress color="primary" />
											</Grid>
										</CardContent>
									</Card>
								)}
							</Grid>
						</Grid>
					</Grid>
				</MuiPickersUtilsProvider>
			</UserContext.Provider>
		);
	}
}

export default withRouter(withLocalize(withStyles(styles)(App)));
