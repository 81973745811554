import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const PasswordField = ({ value, name, label, visible, toggleVisibility, onChange, onKeyPress }) => (
	<Grid item container spacing={1} direction="row" alignItems="flex-end" wrap="nowrap">
		<Grid item>
			<VpnKeyIcon color="action" />
		</Grid>
		<Grid item xs={12}>
			<TextField
				label={label}
				name={name}
				value={value}
				onChange={onChange}
				type={visible ? 'text' : 'password'}
				onKeyPress={onKeyPress}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton aria-label="toggle password visibility" onClick={toggleVisibility}>
								{visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
							</IconButton>
						</InputAdornment>
					),
				}}
				fullWidth
			/>
		</Grid>
	</Grid>
);

const Login = (props) => (
	<Card>
		<CardContent>
			<Grid container direction="column" alignItems="stretch" justify="center" spacing={2}>
				{props.newPasswordRequired ? (
					<>
						<Grid item xs={12}>
							<Typography variant="subtitle1" color="textSecondary">
								<Translate id="login.changePasswordSubtitle" />
							</Typography>
						</Grid>
						<PasswordField
							value={props.oldPassword}
							name="oldPassword"
							label={props.translate('login.oldPassword')}
							visible={props.passwordVisible}
							toggleVisibility={props.togglePasswordVisibility}
							onChange={props.onChange}
							onKeyPress={props.onKeyPress(props.changePassword)}
						/>
						<PasswordField
							value={props.newPassword}
							name="newPassword"
							label={props.translate('login.newPassword')}
							visible={props.passwordVisible}
							toggleVisibility={props.togglePasswordVisibility}
							onChange={props.onChange}
							onKeyPress={props.onKeyPress(props.changePassword)}
						/>
						<PasswordField
							value={props.newPassword2}
							name="newPassword2"
							label={props.translate('login.newPassword2')}
							visible={props.passwordVisible}
							toggleVisibility={props.togglePasswordVisibility}
							onChange={props.onChange}
							onKeyPress={props.onKeyPress(props.changePassword)}
						/>
						<Grid item>
							<Typography color="error" variant="caption">
								{props.error !== null ? <Translate id={`login.errors.${props.error}`} /> : ' '}
							</Typography>
						</Grid>
						<Grid item>
							<Button variant="contained" color="primary" disabled={props.processing} onClick={props.changePassword}>
								<Translate id="login.changePassword" />
							</Button>
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={12}>
							<Typography variant="subtitle1" color="textSecondary">
								<Translate id="login.loginSubtitle" />
							</Typography>
						</Grid>
						<Grid item container spacing={1} direction="row" alignItems="flex-end" wrap="nowrap">
							<Grid item>
								<AccountCircleIcon color="action" />
							</Grid>
							<Grid item xs={12}>
								<TextField
									label={props.translate('login.username')}
									name="username"
									value={props.username}
									onKeyPress={props.onKeyPress(props.login)}
									onChange={props.onChange}
									fullWidth
								/>
							</Grid>
						</Grid>
						<PasswordField
							value={props.password}
							name="password"
							label={props.translate('login.password')}
							visible={props.passwordVisible}
							toggleVisibility={props.togglePasswordVisibility}
							onChange={props.onChange}
							onKeyPress={props.onKeyPress(props.login)}
						/>
						<Grid item>
							<Typography color="error" variant="caption">
								{props.error !== null ? <Translate id={`login.errors.${props.error}`} /> : ' '}
							</Typography>
						</Grid>
						<Grid item>
							<Button variant="contained" color="primary" disabled={props.processing} onClick={props.login}>
								<Translate id="login.login" />
							</Button>
						</Grid>
					</>
				)}
			</Grid>
		</CardContent>
	</Card>
);

export default withLocalize(Login);
