import { config as AWSConfig, CognitoIdentityCredentials } from 'aws-sdk';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
let CognitoUserPoolId = 'eu-west-1_zWA0rYiqu';
let CognitoClientId = '7jnavde145drebhk9su6tmi0s4';
let CognitoIdentityPoolId = 'eu-west-1:55022b94-0ed5-448d-8d0b-a68da0913770';
let Region = 'eu-west-1';

let UserPool = new CognitoUserPool({ UserPoolId: CognitoUserPoolId, ClientId: CognitoClientId });
AWSConfig.region = Region;

export const login = (username, password, newPassword) =>
	new Promise((resolve, reject) => {
		let authenticationData = { Username: username, Password: password };
		let authenticationDetails = new AuthenticationDetails(authenticationData);
		let userData = { Username: username, Pool: UserPool };
		let cognitoUser = new CognitoUser(userData);

		cognitoUser.authenticateUser(authenticationDetails, {
			onSuccess: (result) => {
				let credentials = {
					IdentityPoolId: CognitoIdentityPoolId,
					Logins: {},
				};
				credentials.Logins[`cognito-idp.${Region}.amazonaws.com/${CognitoUserPoolId}`] = result.getIdToken().getJwtToken();

				AWSConfig.credentials = new CognitoIdentityCredentials(credentials);
				AWSConfig.credentials.refresh((err) => (err ? reject(err) : resolve(false)));
			},
			newPasswordRequired: () => {
				if (newPassword) {
					cognitoUser.completeNewPasswordChallenge(
						newPassword,
						{},
						{
							onSuccess: resolve,
							onFailure: reject,
						}
					);
				} else {
					resolve(true);
				}
			},
			onFailure: reject,
		});
	});

export const logout = () =>
	new Promise((resolve) => {
		let cognitoUser = UserPool.getCurrentUser();
		if (cognitoUser !== null) {
			cognitoUser.signOut();
		}
		if (AWSConfig.credentials !== null) {
			AWSConfig.credentials.clearCachedId();
		}
		resolve(null);
	});

export const changePassword = (oldPassword, newPassword) =>
	new Promise((resolve, reject) => {
		let cognitoUser = UserPool.getCurrentUser();

		if (cognitoUser === null) {
			return reject('not_authenticated');
		}
		cognitoUser.getSession((err) => {
			if (err) {
				return reject(err);
			}
			cognitoUser.changePassword(oldPassword, newPassword, (err) => (err ? reject(err) : resolve(null)));
		});
	});

// export const forgotPassword = (username) =>
// 	new Promise((resolve, reject) => {
// 		let userData = { Username: username, Pool: UserPool };
// 		let cognitoUser = new CognitoUser(userData);

// 		cognitoUser.forgotPassword({ onSuccess: resolve, onFailure: reject });
// 	});

// export const resetPassword = (username, verificationCode, newPassword) =>
// 	new Promise((resolve, reject) => {
// 		let userData = { Username: username, Pool: UserPool };
// 		let cognitoUser = new CognitoUser(userData);
// 		cognitoUser.confirmPassword(verificationCode, newPassword, { onSuccess: resolve, onFailure: reject });
// 	});

export const getCurrentUser = () =>
	new Promise((resolve, reject) => {
		let cognitoUser = UserPool.getCurrentUser();

		if (cognitoUser === null) {
			return reject('not_authenticated');
		}
		cognitoUser.getSession((err, session) => {
			if (err) {
				return reject(err);
			}

			cognitoUser.getUserAttributes((err, attributes) => {
				if (err) {
					return reject(err);
				}
				let ret = { id: cognitoUser.username };
				for (let attr of attributes) {
					ret[attr.getName()] = attr.getValue();
				}
				resolve(ret);
			});
		});
	});

export const getAttributes = () =>
	new Promise((resolve, reject) => {
		let cognitoUser = UserPool.getCurrentUser();

		if (cognitoUser === null) {
			return reject('not_authenticated');
		}

		let ret = { id: cognitoUser.username };

		cognitoUser.getSession((err) => {
			if (err) {
				return reject(err);
			}
			cognitoUser.getUserAttributes((err, result) => {
				if (err) {
					return reject(err);
				}
				for (let i = 0; i < result.length; i++) {
					ret[result[i].getName()] = result[i].getValue();
				}
				resolve(ret);
			});
		});
	});

export const updateAttribute = (name, value) =>
	new Promise((resolve, reject) => {
		let cognitoUser = UserPool.getCurrentUser();

		if (cognitoUser === null) {
			return reject('not_authenticated');
		}

		let attributeList = [];
		let attribute = new CognitoUserAttribute({ Name: name, Value: value });
		attributeList.push(attribute);

		cognitoUser.getSession((err) => {
			if (err) {
				return reject(err);
			}
			cognitoUser.updateAttributes(attributeList, (err) => (err ? reject(err) : resolve(null)));
		});
	});

export const refresh = (firstTime) =>
	new Promise((resolve, reject) => {
		let cognitoUser = UserPool.getCurrentUser();

		if (cognitoUser === null) {
			return reject('not_authenticated');
		}
		cognitoUser.getSession((err, session) => {
			if (err) {
				return reject('not_authenticated');
			}
			let refreshToken = session.getRefreshToken();

			let credentials = {
				IdentityPoolId: CognitoIdentityPoolId,
				Logins: {},
			};
			credentials.Logins[`cognito-idp.${Region}.amazonaws.com/${CognitoUserPoolId}`] = session.getIdToken().getJwtToken();
			if (firstTime) {
				AWSConfig.credentials = new CognitoIdentityCredentials(credentials);
			} else {
				AWSConfig.credentials.params.Logins[`cognito-idp.${Region}.amazonaws.com/${CognitoUserPoolId}`] = session.getIdToken().getJwtToken();
			}

			if (AWSConfig.credentials.needsRefresh()) {
				cognitoUser.refreshSession(refreshToken, (err, session) => {
					if (err) {
						return reject('not_authenticated');
					}
					credentials.Logins[`cognito-idp.${Region}.amazonaws.com/${CognitoUserPoolId}`] = session.getIdToken().getJwtToken();
					AWSConfig.credentials.params.Logins[`cognito-idp.${Region}.amazonaws.com/${CognitoUserPoolId}`] = session.getIdToken().getJwtToken();
					AWSConfig.credentials.refresh((err) => {
						if (err) {
							return reject('not_authenticated');
						}
						resolve(credentials);
					});
				});
			} else {
				resolve(credentials);
			}
		});
	});
