import 'date-fns';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import App from './presentation';

const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
	},
	palette: {
		primary: {
			light: amber['600'],
			main: amber['700'],
			dark: amber['800'],
			contrastText: '#333333',
		},
		info: {
			light: amber['600'],
			main: amber['700'],
			dark: amber['800'],
			contrastText: '#333333',
		},
	},
});

const AppContainer = () => (
	<LocalizeProvider>
		<BrowserRouter basename="/">
			<MuiThemeProvider theme={theme}>
				<App />
			</MuiThemeProvider>
		</BrowserRouter>
	</LocalizeProvider>
);

export default AppContainer;
