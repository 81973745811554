import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import HomeDetails from './presentation.details';

const useStyles = makeStyles({
	paper: {
		maxHeight: 'calc(100vh - 10rem)',
		overflow: 'auto',
	},
	filterContainer: {
		paddingBottom: 0,
		paddingTop: 0,
	},
	searchTextField: {
		flex: 1,
		marginBottom: '1rem',
	},
	headerCell: {
		borderBottom: 0,
	},
});

const timeToString = (time, locale) => {
	let date = new Date(time * 1000);
	return date.toLocaleTimeString(locale, { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' });
};

const Home = (props) => {
	const classes = useStyles(props);
	return (
		<Paper className={classes.paper}>
			<HomeDetails open={props.selectedMixer !== null} onClose={props.closeDetails} mixer={props.selectedMixer} />
			<LinearProgress hidden={!props.processing} />
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell className={classes.headerCell}>
								<Translate id="home.id" />
							</TableCell>
							<TableCell className={classes.headerCell}>
								<Translate id="home.name" />
							</TableCell>
							<TableCell className={classes.headerCell}>
								<Translate id="home.fw" />
							</TableCell>
							<TableCell className={classes.headerCell}>
								<Translate id="home.lastUpdate" />
							</TableCell>
							<TableCell className={classes.headerCell}>
								<Translate id="home.bluetooth" />
							</TableCell>
							<TableCell className={classes.headerCell}>
								<Grid container direction="row" alignItems="center" justify="space-between" wrap="nowrap">
									<IconButton aria-label="refresh" onClick={props.fetchMixers}>
										<RefreshIcon />
									</IconButton>
									<IconButton aria-label="filters" onClick={props.toggleFilters}>
										<FilterListIcon />
									</IconButton>
								</Grid>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={99} className={classes.filterContainer}>
								<Collapse in={props.filters.open} timeout="auto">
									<TextField
										className={classes.searchTextField}
										InputProps={{ disableUnderline: true }}
										value={props.filters.value}
										onChange={props.filterChanged}
										placeholder={props.translate('filter')}
										fullWidth
									/>
								</Collapse>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.mixers.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((row) => (
							<TableRow key={row.id}>
								<TableCell>{row.id}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.fw}</TableCell>
								<TableCell>{timeToString(row.lastUpdate, props.locale)}</TableCell>
								<TableCell>{row.bluetooth}</TableCell>
								<TableCell>
									<IconButton aria-label="refresh" onClick={props.openDetails(row)}>
										<SearchIcon />
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={props.mixers.length}
				rowsPerPage={props.rowsPerPage}
				page={props.page}
				onChangePage={props.handleChangePage}
				onChangeRowsPerPage={props.handleChangeRowsPerPage}
				labelRowsPerPage={<Translate id="rowsPerPage" />}
			/>
		</Paper>
	);
};

export default withLocalize(Home);
