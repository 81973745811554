import React from 'react';

const UserContext = React.createContext({
	id: '',
	email: '',
	name: '',
	changeName: (n) => {},
	changeLocale: (l) => {},
});

export default UserContext;
