import React from 'react';
import { logout as CognitoLogout, login as CognitoLogin } from '../../aws/cognito';
import Login from './presentation';

class LoginContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: true,
			username: '',
			password: '',
			passwordVisible: false,
			oldPassword: '',
			newPassword: '',
			newPassword2: '',
			error: null,
			newPasswordRequired: false,
		};
		this.onKeyPress = this.onKeyPress.bind(this);
		this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
		this.onChange = this.onChange.bind(this);
		this.login = this.login.bind(this);
		this.changePassword = this.changePassword.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	onKeyPress(cb) {
		return (e) => (e.key === 'Enter' ? cb() : null);
	}

	togglePasswordVisibility() {
		this.secureSetState({ passwordVisible: !this.state.passwordVisible });
	}

	onChange(e) {
		let { name, value } = e.target;
		this.secureSetState({ [name]: value });
	}

	login() {
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true, error: null, passwordVisible: false }, async () => {
			if (this.state.username.length === 0 || this.state.password.length === 0) {
				this.secureSetState({ processing: false, error: 'mandatory' });
				return;
			}
			try {
				let newPasswordRequired = await CognitoLogin(this.state.username, this.state.password);
				if (!newPasswordRequired) {
					setTimeout(() => (window.location.href = '/'), 500);
					return;
				}
				this.secureSetState({ processing: false, error: null, newPasswordRequired: newPasswordRequired });
			} catch (err) {
				console.log(err.code);
				switch (err.code) {
					case 'UserNotFoundException':
						this.secureSetState({ processing: false, error: 'notFound' });
						break;
					case 'NotAuthorizedException':
						this.secureSetState({ processing: false, error: 'incorrect' });
						break;
					default:
						this.secureSetState({ processing: false, error: 'generic' });
						break;
				}
			}
		});
	}

	changePassword() {
		if (this.state.processing) {
			return;
		}

		this.secureSetState({ processing: true, error: null, passwordVisible: false }, async () => {
			if (
				this.state.username.length === 0 ||
				this.state.oldPassword.length === 0 ||
				this.state.newPassword.length === 0 ||
				this.state.newPassword2.length === 0
			) {
				this.secureSetState({ processing: false, error: 'mandatory' });
				return;
			}
			if (this.state.newPassword !== this.state.newPassword2) {
				this.secureSetState({ processing: false, error: 'dontMatch' });
				return;
			}
			try {
				await CognitoLogin(this.state.username, this.state.oldPassword, this.state.newPassword);
				setTimeout(() => (window.location.href = '/'), 500);
			} catch (err) {
				console.log(err.code);
				switch (err.code) {
					case 'UserNotFoundException':
						this.secureSetState({ processing: false, error: 'notFound' });
						break;
					case 'NotAuthorizedException':
						this.secureSetState({ processing: false, error: 'incorrect' });
						break;
					case 'InvalidPasswordException':
						this.secureSetState({ processing: false, error: 'invalid' });
						break;
					default:
						this.secureSetState({ processing: false, error: 'generic' });
						break;
				}
			}
		});
	}

	async componentDidMount() {
		this.isMounted = true;
		document.title = 'Bernardi mixers';
		await CognitoLogout();
		this.secureSetState({ processing: false });
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Login
				{...this.state}
				onKeyPress={this.onKeyPress}
				togglePasswordVisibility={this.togglePasswordVisibility}
				onChange={this.onChange}
				login={this.login}
				changePassword={this.changePassword}
			/>
		);
	}
}

export default LoginContainer;
