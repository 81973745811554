import React from 'react';
import { Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { getCurrentUser as CognitoGetCurrentUser } from '../../aws/cognito';

class DeleteUserDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			deleted: false,
			error: null,
		};
		this.submit = this.submit.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	submit() {
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true }, async () => {
			try {
				await CognitoGetCurrentUser();
				let r = await fetch(`https://api.bernardimixers.com/administrators/${this.props.user}`, {
					method: 'DELETE',
					headers: { 'X-Api-Key': '3dFmMoyN725JzNimeIuBw47wEHJzMpsd1EHAx8K1' },
				});
				let resp = await r.json();
				if (resp.hasOwnProperty('err')) {
					this.secureSetState({ processing: false, error: 'generic' });
					return;
				}

				this.secureSetState({ processing: false, error: null, deleted: true });
			} catch (err) {
				console.log(err);
				if (err === 'not_authenticated') {
					window.location.href = '/';
					return;
				}
				this.secureSetState({ processing: false, error: 'generic' });
			}
		});
	}
	componentDidMount() {
		this.isMounted = true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.user !== null && this.props.user === null) {
			this.secureSetState({ processing: false, deleted: false, error: null });
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Dialog open={this.props.user !== null} aria-labelledby="delete-user-dialog" maxWidth="xs" fullWidth>
				<LinearProgress hidden={!this.state.processing} />
				<DialogTitle id="delete-user-dialog">
					<Translate id="users.delete.title" />
				</DialogTitle>
				{this.state.deleted ? (
					<>
						<DialogContent>
							<DialogContentText>
								<Translate id="users.delete.deleted" />
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button variant="text" color="primary" onClick={this.props.onClose} disabled={this.state.processing}>
								<Translate id="users.delete.close" />
							</Button>
						</DialogActions>
					</>
				) : (
					<>
						<DialogContent>
							<DialogContentText>
								<Translate id="users.delete.question" />
							</DialogContentText>
							<DialogContentText>{this.props.user}</DialogContentText>
							{this.state.error !== null && (
								<Typography color="error" variant="caption">
									<Translate id={`users.delete.errors.${this.state.error}`} />
								</Typography>
							)}
						</DialogContent>
						<DialogActions>
							<Button variant="contained" color="primary" onClick={this.submit} disabled={this.state.processing}>
								<Translate id="users.delete.submit" />
							</Button>
							<Button variant="text" color="primary" onClick={this.props.onClose} disabled={this.state.processing}>
								<Translate id="users.delete.close" />
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
		);
	}
}

export default DeleteUserDialog;
