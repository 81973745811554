import React from 'react';
import { Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { getCurrentUser as CognitoGetCurrentUser } from '../../aws/cognito';

class CreateUserDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			created: false,
			user: {
				id: '',
				name: '',
				locale: 'it',
				password: '',
			},
			error: null,
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
		this.copyPassword = this.copyPassword.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	handleChange(e) {
		let { name, value } = e.target;
		let { user } = this.state;
		user[name] = value;

		this.secureSetState({ user, error: null });
	}

	async copyPassword() {
		try {
			await navigator.clipboard.writeText(this.state.user.password);
		} catch (err) {
			console.log(err);
		}
	}
	submit() {
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true }, async () => {
			let { user } = this.state;
			user.id = user.id.trim();
			user.name = user.name.trim();
			user.locale = user.locale.trim();
			if (user.id.length === 0 || user.name.length === 0 || user.locale.length === 0) {
				this.secureSetState({ processing: false, error: 'mandatory' });
				return;
			}
			try {
				await CognitoGetCurrentUser();
				let r = await fetch('https://api.bernardimixers.com/administrators', {
					method: 'POST',
					headers: { 'X-Api-Key': '3dFmMoyN725JzNimeIuBw47wEHJzMpsd1EHAx8K1' },
					body: JSON.stringify({
						id: user.id,
						name: user.name,
						locale: user.locale,
					}),
				});
				let resp = await r.json();
				if (resp.hasOwnProperty('err')) {
					switch (resp.err) {
						case 'AlreadyExists':
							this.secureSetState({ processing: false, error: 'duplicate' });
							break;
						default:
							this.secureSetState({ processing: false, error: 'generic' });
					}
					return;
				}

				this.secureSetState({ processing: false, error: null, user: resp, created: true });
			} catch (err) {
				console.log(err);
				if (err === 'not_authenticated') {
					window.location.href = '/';
					return;
				}
				this.secureSetState({ processing: false, error: 'generic' });
			}
		});
	}
	componentDidMount() {
		this.isMounted = true;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.open && !this.props.open) {
			this.secureSetState({
				processing: false,
				created: false,
				user: {
					id: '',
					name: '',
					locale: 'it',
					password: '',
				},
				error: null,
			});
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Dialog open={this.props.open} aria-labelledby="create-user-dialog" maxWidth="xs" fullWidth>
				<LinearProgress hidden={!this.state.processing} />
				<DialogTitle id="create-user-dialog">
					<Translate id="users.create.title" />
				</DialogTitle>
				{this.state.created ? (
					<>
						<DialogContent>
							<DialogContentText>
								<Translate id="users.create.created" />
							</DialogContentText>
							<Typography component="p" color="textPrimary" variant="body1">
								<Translate id="users.id" />
								{`: ${this.state.user.id}`}
							</Typography>
							<Typography component="p" color="textPrimary" variant="body1">
								<Translate id="users.password" />
								{`: ${this.state.user.password}`}
							</Typography>
							<Typography component="p" color="textPrimary" variant="body1">
								<Translate id="users.name" />
								{`: ${this.state.user.name}`}
							</Typography>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" color="primary" onClick={this.copyPassword} disabled={this.state.processing}>
								<Translate id="users.create.copyPwd" />
							</Button>
							<Button variant="text" color="primary" onClick={this.props.onClose} disabled={this.state.processing}>
								<Translate id="users.create.close" />
							</Button>
						</DialogActions>
					</>
				) : (
					<>
						<DialogContent>
							<Grid container direction="column" alignItems="center" justify="flex-start">
								<TextField
									label={<Translate id="users.id" />}
									name="id"
									value={this.state.user.id}
									disabled={this.state.processing}
									onChange={this.handleChange}
									margin="normal"
									fullWidth
								/>
								<TextField
									label={<Translate id="users.name" />}
									name="name"
									value={this.state.user.name}
									disabled={this.state.processing}
									onChange={this.handleChange}
									margin="normal"
									fullWidth
								/>
								{this.state.error !== null && (
									<Typography color="error" variant="caption">
										<Translate id={`users.create.errors.${this.state.error}`} />
									</Typography>
								)}
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button variant="contained" color="primary" onClick={this.submit} disabled={this.state.processing}>
								<Translate id="users.create.submit" />
							</Button>
							<Button variant="text" color="primary" onClick={this.props.onClose} disabled={this.state.processing}>
								<Translate id="users.create.close" />
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
		);
	}
}

export default CreateUserDialog;
