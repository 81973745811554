import React from 'react';
import { getCurrentUser as CognitoGetCurrentUser } from '../../aws/cognito';
import UserContext from '../../context';
import Users from './presentation';

class UsersContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			users: [],
			count: 0,
			rowsPerPage: 10,
			page: 0,
			dialogCreateOpen: false,
			userToDelete: null,
		};
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
		this.toggleDialogCreate = this.toggleDialogCreate.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.closeDeleteUserDialog = this.closeDeleteUserDialog.bind(this);
		this.fetchUsers = this.fetchUsers.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	toggleDialogCreate() {
		if (this.state.dialogCreateOpen) {
			this.secureSetState({ dialogCreateOpen: false }, this.fetchUsers);
		} else {
			this.secureSetState({ dialogCreateOpen: true });
		}
	}

	deleteUser(id) {
		return () => this.secureSetState({ userToDelete: id });
	}

	closeDeleteUserDialog() {
		this.secureSetState({ userToDelete: null }, this.fetchUsers);
	}

	handleChangePage(e, page) {
		this.secureSetState({ page });
	}

	handleChangeRowsPerPage(e) {
		this.secureSetState({ page: 0, rowsPerPage: Math.abs(e.target.value) });
	}

	fetchUsers() {
		this.secureSetState({ processing: true }, async () => {
			try {
				await CognitoGetCurrentUser();
				let r = await fetch('https://api.bernardimixers.com/administrators', {
					method: 'GET',
					headers: { 'X-Api-Key': '3dFmMoyN725JzNimeIuBw47wEHJzMpsd1EHAx8K1' },
				});
				let resp = await r.json();
				if (resp.hasOwnProperty('users') && resp.hasOwnProperty('count')) {
					this.secureSetState({
						users: resp.users.sort((a, b) => b.lastUpdate - a.lastUpdate),
						count: resp.count,
						processing: false,
					});
				}
			} catch (err) {
				console.log(err);
				if (err === 'not_authenticated') {
					window.location.href = '/';
					return;
				}

				this.secureSetState({
					users: [],
					count: 0,
					processing: false,
				});
			}
		});
	}

	async componentDidMount() {
		this.isMounted = true;
		document.title = 'Utenti | Bernardi mixers';
		this.fetchUsers();
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Users
				{...this.state}
				self={this.context}
				handleChangePage={this.handleChangePage}
				handleChangeRowsPerPage={this.handleChangeRowsPerPage}
				fetchUsers={this.fetchUsers}
				toggleDialogCreate={this.toggleDialogCreate}
				deleteUser={this.deleteUser}
				closeDeleteUserDialog={this.closeDeleteUserDialog}
			/>
		);
	}
}

UsersContainer.contextType = UserContext;
export default UsersContainer;
