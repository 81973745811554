import React from 'react';
import { Translate } from 'react-localize-redux';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import RefreshIcon from '@material-ui/icons/Refresh';
import CreateUserDialog from './presentation.create';
import DeleteUserDialog from './presentation.delete';

const useStyles = makeStyles({
	paper: {
		maxHeight: 'calc(100vh - 10rem)',
		overflow: 'auto',
	},
});

const Users = (props) => {
	const classes = useStyles(props);
	return (
		<Paper className={classes.paper}>
			<CreateUserDialog open={props.dialogCreateOpen} onClose={props.toggleDialogCreate} />
			<DeleteUserDialog user={props.userToDelete} onClose={props.closeDeleteUserDialog} />
			<LinearProgress hidden={!props.processing} />
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>
								<Translate id="users.id" />
							</TableCell>
							<TableCell>
								<Translate id="users.name" />
							</TableCell>
							<TableCell>
								<Translate id="users.status" />
							</TableCell>
							<TableCell>
								<Grid container direction="row" alignItems="center" justify="space-between">
									<IconButton aria-label="refresh" onClick={props.fetchUsers}>
										<RefreshIcon />
									</IconButton>
									<IconButton aria-label="refresh" onClick={props.toggleDialogCreate}>
										<PlusOneIcon />
									</IconButton>
								</Grid>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.users.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((row) => (
							<TableRow key={row.id}>
								<TableCell>{row.id}</TableCell>
								<TableCell>{row.name}</TableCell>
								<TableCell>{row.status}</TableCell>
								<TableCell>
									{row.id !== props.self.id && (
										<IconButton aria-label="Delete user" onClick={props.deleteUser(row.id)}>
											<DeleteIcon />
										</IconButton>
									)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100]}
				component="div"
				count={props.count}
				rowsPerPage={props.rowsPerPage}
				page={props.page}
				onChangePage={props.handleChangePage}
				onChangeRowsPerPage={props.handleChangeRowsPerPage}
				labelRowsPerPage={<Translate id="rowsPerPage" />}
			/>
		</Paper>
	);
};

export default Users;
